import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store' 
import HomeView from '../views/HomeView.vue'

import LoginView from '@/views/LoginView'
import UserlistView from '@/views/UserlistView'
import KeywordsView from '@/views/KeywordsView'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/user-list',
    name: 'UserlistView',
    component: UserlistView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/keywords',
    name: 'KeywordsView',
    component: KeywordsView,
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.is_login) {
      next({ name: 'LoginView' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router

