import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
   
    host_url: `${window.location.origin}/php_script/`,
    is_login: false,
 
    username: "",
  },
  getters: {
    
  },
  mutations: {
    
    HostUrl(state,host_url) {
      state.host_url = host_url
    },
    IsLogin(state, is_login) {
      state.is_login = is_login
    },
    Username(state, data) {
      state.username = data
    },
    
    
      
  },
  actions: {

  },
  plugins: [new VuexPersistence().plugin]

})
export default store