<template>
  <header class="shadow-sm">        
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <!-- logo -->
          <a href="/">
            <img class="logo" src="@/assets/images/logo.png" alt="logo" width="1518" height="185">
          </a>
        </div>

        <!-- login.logout button -->
        <div class="col-md-6 py-0 d-md-flex d-none">
          <a class="log-btn" href="/login" v-if="!IsLogin">
            <span>Log Masuk <i class="bi bi-box-arrow-in-right"></i></span>
          </a>
          <a class="log-btn" v-if="IsLogin" @click="logout" >
            <span>Log Keluar <i class="bi bi-box-arrow-right"></i></span>
          </a>
        </div>
      </div>
    </div>
  </header>
  
</template>

<script>
// @ is an alias to /src
import store from "../store";
export default {
  data(){
    return{}
  },
  methods: {
    logout(){
        store.commit("IsLogin", false);
        store.commit("Username", "");
        this.$router.push("/login");
    },
  },
  computed: {
    
    HostUrl() {
        return this.$store.state.host_url;
    },
    IsLogin() {
        return this.$store.state.is_login;
    },

  }
}
</script>
<style>
  :root{
    --main-purple: #5C2E91;
    --main-purple-2: #8f62c3;
    --main-purple-3: #411e68;
    --main-green:#4DB848;    
    --main-green-2:#71d36c;
    --main-green-3:#368b31;
    --main-grey-1: #CBCBCB;
    --main-grey-2: #ababab;
    --main-grey-3: #999999;
    --header-height:60px;
    --nav-height: 50px;
    --footer-height: 40px;
  }
  .row{
    margin: 0;
    padding: 0;
  }
  .container{
    padding: 0;
  }
  header .container{
    padding: 0 .5rem;
  }
  header .logo {
    max-width: 100%;
    width: auto;
    max-height: var(--header-height);
    height: auto;
    display: flex;
    align-items: center;
  }
  header .log-btn {    
    width: 100%;
    height: 100%;    
    text-decoration: none;    
  }
  header .log-btn span{        
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: auto;    
    color: #000;
    font-weight: 600;
    padding: 0 25px;
    transition: background-color .35s ease,
                color .35s ease;
  }
  header .log-btn span:hover {
    background-color: var(--main-green);
    color: #fff;
  }
  header .log-btn span i{
    font-size: 20px;
    margin-left: 5px;
  }  

  /* ===================================
  Custom Css
  =================================== */
  .body-container .container{
    padding: .5rem 2rem;
  }
  .body-container{
    margin-top: 2rem;
    margin-bottom: calc( 2rem + var(--footer-height) );
  }
  .container-title{
    margin-bottom: 40px;
  }
  .table-box{
    padding: 1rem 0;
    border: 1px solid var(--main-grey-1);
    border-radius: 6px;
  }
  .border-box{
    padding: 1rem 0;
    border: 1px solid var(--main-grey-1);
    border-radius: 6px;
  }
  .graph-box{
    padding: 1rem;
    width: 100%;
    margin: 0 auto;
  }
  .graph-box div{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .graph-box canvas {
    /* max-width: 1000px; */
    /* max-height: 500px; */
    height: auto !important;
    width: 90% !important;
  }
  .main-select {
    background-color: rgb(0 0 0 / .05);
    border: 1px solid rgb(0 0 0 / .1);
    border-radius: 6px;
    padding: 0 15px;
  }
</style>