<template>
    <footer>
      <div class="footer-text">
        <p>@ Copyright</p>
      </div>
    </footer>
</template>

<script>
// @ is an alias to /src

export default {
  data(){
    return{}
  }
}
</script>
<style>
  footer{
    position: absolute;
    bottom: 0;
    left: 0;
    min-width: 100%;
    height: var(--footer-height);
    color: #fff;
    background: var(--main-green);    
  }
  .footer-text{
    height: 100%;
  }
  .footer-text p {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
