<template>
<div>
    <HeaderNav/>
    <MainNav/>

    <div class="container mt-5">
        <!-- ===== Content ===== -->
        <div class="body-container">
            <div class="row mx-auto">
                <div class="col-12">
                    <div class="dashboard-content-card text-center">
                        <h3><b>Keywords to crawl in Twitter for LPPKN Analytics</b></h3>
                        <hr>
                        <div class="dashboard-table">
                            <div class="table-responsive" v-if="datas != null">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th class="text-left">Keywords</th>
                                            <th class="text-left">Descriptions</th>
                                            <th class="text-left">Categories</th>
                                            <th>Action </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><input type='text' v-model='name'></td>
                                            <td><input type='text' v-model='description'></td>
                                            <td> 
                                                <select style="cursor: pointer;" v-model="category" id="select-category" >
                                                    <option disabled value="">Choose a category ...</option>
                                                    <option v-for="(category_data, index) in category_datas"
                                                            :key="index" :value="category_data.ID">
                                                        {{ category_data.name }}</option>
                                                 </select>
                                            </td>
                                            <td>
                                                <v-btn depressed color="green" dark @click="addKeyword">Add New Keywords</v-btn>
                                            </td>

                                        </tr>
                                        <tr v-for="(data, index) in datas" :key="index">
                                            <td><input type='text' v-model='data.name'></td>
                                            <td><input type='text' v-model='data.description'></td>
                                            <td><select style="cursor: pointer;" v-model="data.category_ID" id="select-category" >
                                                    <option disabled value="">Choose a category ...</option>
                                                    <option v-for="(category_data, index) in category_datas"
                                                            :key="index" :value="category_data.ID">
                                                        {{ category_data.name }}</option>
                                                 </select></td>
                                            <td>
                                                <v-btn depressed color="blue" dark @click="editKeyword(index,data.ID)">Update </v-btn>
                                                <v-btn depressed color="red" dark @click="deleteKeyword(data.ID)">Delete </v-btn>
                                            </td>
                                        </tr>

                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                                
                            </div>  <!-- <=== END dashboard-content-card -->
                        </div>
                    </div>
                </div>  <!-- <=== End Content  -->


            <FooterBar/>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import HeaderNav from '@/components/HeaderNav.vue';
import MainNav from '@/components/MainNav.vue';
import FooterBar from '@/components/FooterBar.vue';
//import store from '../store'
export default {
    name: "AdminUserlist",
    components: {
    HeaderNav,
    MainNav,
    FooterBar
},
    data() {

        return {
            datas: [],
            show: false,
            name: "",
            description: "",
            url:"",
            category: "",
            category_datas:[],
            category_url: ""
        };
    },
    methods: {
        addKeyword() {

            if (this.name != '' ) {
                axios.post(this.url, {
                        request: 2,
                        name: this.name,
                        description: this.description,
                        user: this.Username,
                        category: this.category

                    })
                    .then(response => {
                        this.name = '';
                        this.description = '';
                        this.category = ''
                        this.showKeyword();
                        console.log(response.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                alert('Fill all fields.');
            }

        },
        editKeyword(index, id) {
            // update meetings in firestores
            var name = this.datas[index].name;
            var description = this.datas[index].description;
            var category = this.datas[index].category_ID;

            axios.post(this.url, {
                    request: 3,
                    id: id,
                    name: name,
                    description: description,
                    user: this.Username,
                    category: category
                })
                .then(response => {
                    alert(response.data);
                    this.show_user();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        showKeyword() {
            axios.post(this.url, {
                    request: 1,
                })
                .then(response => {

                    this.datas = response.data;

                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        deleteKeyword(id) {

            axios.post(this.url, {
                    request: 4,
                    id: id

                })
                .then(response => {
                    alert(response.data);
                    this.showKeyword();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        showCategory() {
            axios.post(this.category_url, {
                    request: 1,
                })
                .then(response => {
                    console.log(response.data)
                    this.category_datas = response.data;

                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
    },
    created() {

        this.url = this.HostUrl +  "keywords.php"
        this.category_url = this.HostUrl +  "category.php"

        this.showKeyword()
        this.showCategory()
    
    },

    computed: {
        HostUrl() {
            return this.$store.state.host_url;
        },
        Username() {
            return this.$store.state.username;
        }
    }

};
</script>

<style>
select {
    display: grid;
}
td button{
    margin: 2px 1px;
}
</style>
