<template>
    <div>
        <HeaderNav/>
        <MainNav/>
        
        <div id="login-page">
            <div class="login-card text-center">
                <h3 class="mt-4"><b>Log Masuk</b></h3>
                <form @submit.prevent="login(username,password)">
                    <div class="form-group">
                        <input type="text" class="form-control" id="username" placeholder="Username" v-model="username">
                    </div>
                    <div class="form-group position-relative">
                        <input type="password" class="form-control" id="password" placeholder="Password" v-model="password" v-on:keyup.enter="login(username,password)">
                        <div class="show-password"><i class="bi bi-eye-fill"></i></div>
                        <a class="forget-password">Forget password?</a>
                    </div>
                        
                    <button class="btn-main" type="submit">Log Masuk</button>
                </form>
            </div>
        </div>

        <FooterBar/>
    </div>
</template>

<script>
import store from "../store";
import axios from 'axios';
import HeaderNav from "@/components/HeaderNav.vue";
import MainNav from "@/components/MainNav.vue";
import FooterBar from "@/components/FooterBar.vue";

export default {
    components: {
    HeaderNav,
    MainNav,
    FooterBar
},
    data() {
        return {
            username: "",
            password: "",
            feedback: "",
            result: '',
  
            url: ''
        };
    },
    methods: {

        login: function (username, password) {
            //this.show = true;
            
            this.feedback = ''
            if(username == "") {
                this.feedback = "Please enter your username.";
                return
            }
            if(password == "") {
                this.feedback = "Password cannot be blank."
                return
            }
    
            axios.post(this.url, 
                {
                    username: username,
                    password: password
                }
                )
                .then(response => {
                    
                    console.log(response.data)
                    this.result = response.data;
                    if(this.result[0].login_status == "success") {
                        store.commit("IsLogin", true);
                        store.commit("Username", username);
                        this.$router.push("/");
                    }
                    else {
                        this.feedback = this.result
                    }
                    this.show = false
                    
                })
                
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
           

        },
        

    },
    created() {
        store.commit("HostUrl","https://lppknanalitik.com/php_script/")
    
        this.url = this.HostUrl + "login.php"
       
       

    },

    computed: {
       
        HostUrl() {
            return this.$store.state.host_url;
        },
       
    }
};
</script>
<style>
    #login-page{
        position: relative;
        min-height: calc( 100vh - var(--header-height) - var(--nav-height) - var(--footer-height) );
    }
    .login-card {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50% , -50%);
        padding: 40px 30px;
        border-radius: 4px;
        width: 80%;
        max-width: 600px;
        max-height: calc( 100vh - var(--header-height) - var(--nav-height) - var(--footer-height) - 100px  );
        /* margin-top: 25px; */
        border: 2px solid var(--main-purple);        
        overflow: auto;
    }
    .login-card form{
        width: 90%;
        padding: .5rem 0;
        margin: 0 auto;
    }
    .login-card form input{
        border: 1px solid rgba(0,0,0,.5) !important;
        border-radius: 4px !important;
        padding: 0 10px !important;
        margin: 20px auto 0 auto !important;
        width: 90% !important;
    }
    .login-card form select{
        border: 1px solid rgba(0,0,0,.5) !important;
        border-radius: 4px !important;
        padding: 0 10px !important;
        margin: 20px auto 0 auto !important;
        width: calc(100% - 20px) !important;
    }
    .login-card form button{
        background-color: var(--main-green);
        color: #fff;
        min-height: 50px;
        min-width: 100px;
        width: 50%;
        margin: 2.5rem 0 0 0;
        border-radius: 4px;
        transition: background-color .35s ease;
    }
    .login-card form button:hover {
        background-color: var(--main-green-3);
    }
    .show-password {
        position: absolute;
        left: calc(88% - 20px);
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        padding: 5px 20px;
    }
    .forget-password{
        position: absolute;
        bottom: -55%;
        left: 5%;
    }
    .show-password i{
    font-size: 20px;
    transition: color .4s ease;
    color: rgb(0 0 0 / .5);
    }
    .show-password:hover i{
        color: rgba(0,0,0,.75);
    }
</style>