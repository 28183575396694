<template>
  <div>
    <HeaderNav />
    <MainNav />

    <div class="body-container">
      <div class="container">

        <div class="page-button-container">
          <!-- Tabs -->
          <div
            class="page-button ms-0"
            :class="{ active: activeTab === 'page-1' }"
            @click="setActiveTab('page-1')"
          >
            MENU UTAMA
          </div>
          <div
            class="page-button"
            :class="{ active: activeTab === 'page-2' }"
            @click="setActiveTab('page-2')"
          >
            CARIAN TWEET
          </div>
          <div
            class="page-button"
            :class="{ active: activeTab === 'page-3' }"
            @click="setActiveTab('page-3')"
          >
            Awan Perkataan
          </div>
        </div>

        <div class="page-button-content">
          <!-- Page 1 -->
          <div v-show="activeTab === 'page-1'" class="row">
            <div class="col-12 container-title">
              <h1 style="text-align: center">
                LPPKN: Papan Pemuka Pengetahuan,
                Persepsi dan Perilaku Remaja Berkenaan Kesihatan
                Reproduksi dan Seksual di Media Sosial
              </h1>
            </div>
            <div class="col-12">
              <div class="graph-box mb-4">
                <BarChart />
              </div>
            </div>
          </div>

          <!-- Page 2 -->
          <div v-show="activeTab === 'page-2'" class="row">
            <div class="col-12 col-md-4">
              <select
                class="main-select year_chosen"
                v-model="year_chosen"
                @change="onChangeResult"
              >
                <option disabled value="">Pilih Tahun ...</option>
                <option
                  v-for="(year_data, index) in year_datas"
                  :key="index"
                  :value="year_data.Year"
                >
                  {{ year_data.Year }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-4">
              <select
                class="main-select month_chosen"
                v-model="month_chosen"
                @change="onChangeResult"
              >
                <option disabled value="">Pilih Bulan ...</option>
                <option
                  v-for="(month_data, index) in filteredMonthDatas"
                  :key="index"
                  :value="month_data.Month"
                >
                  {{ month_data.Month }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-4">
              <select
                class="main-select keyword_chosen"
                v-model="keyword_chosen"
                @change="onChangeResult"
              >
                <option disabled value="">Pilih Katakunci ...</option>
                <option
                  v-for="(keyword_data, index) in keyword_datas"
                  :key="index"
                  :value="keyword_data.ID"
                >
                  {{ keyword_data.name }}
                </option>
              </select>
            </div>
            <div class="table-box p-2">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Tweet</th>
                    <th>Kata Kunci</th>
                    <th>Kategori</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(data, index) in pageOfItems"
                    :key="index"
                  >
                    <td>{{ data.twitter_text }}</td>
                    <td>{{ data.query_keyword }}</td>
                    <td>{{ data.name }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="card-footer">
                <jw-pagination
                  :items="datas"
                  :pageSize="10"
                  @changePage="onChangePage"
                  :labels="customLabels"
                />
              </div>
            </div>
          </div>

          <!-- Page 3 -->
          <div v-show="activeTab === 'page-3'" class="row">
            <div class="col-12 col-md-4">
              <select
                class="main-select year_chosen"
                v-model="year_chosen"
                @change="onChangeResult"
              >
                <option disabled value="">Pilih Tahun ...</option>
                <option
                  v-for="(year_data, index) in year_datas"
                  :key="index"
                  :value="year_data.Year"
                >
                  {{ year_data.Year }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-4">
              <select
                class="main-select month_chosen"
                v-model="month_chosen"
                @change="onChangeResult"
              >
                <option disabled value="">Pilih Bulan ...</option>
                <option
                  v-for="(month_data, index) in filteredMonthDatas"
                  :key="index"
                  :value="month_data.Month"
                >
                  {{ month_data.Month }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-4">
              <select
                class="main-select keyword_chosen"
                v-model="keyword_chosen"
                @change="onChangeResult"
              >
                <option disabled value="">Pilih Katakunci ...</option>
                <option
                  v-for="(keyword_data, index) in keyword_datas"
                  :key="index"
                  :value="keyword_data.ID"
                >
                  {{ keyword_data.name }}
                </option>
              </select>
            </div>
            <div class="text-center mt-5">
              <img v-if="word_cloud_url" :src="word_cloud_url" alt="Word Cloud" />
              <p v-else>Sila Pilih Tahun, Bulan dan Kata Kunci</p>
            </div>
          </div>
        </div>

      </div>
    </div>

    <FooterBar />
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue";
import MainNav from "@/components/MainNav.vue";
import FooterBar from "@/components/FooterBar.vue";
import BarChart from "@/components/BarChart.vue";
import axios from "axios";
import JwPagination from "jw-vue-pagination";
import store from "../store";

export default {
  name: "HomeView",
  components: { HeaderNav, MainNav, FooterBar, BarChart, JwPagination },
  data() {
    return {
      activeTab: "page-1",
      year_chosen: "",
      month_chosen: "",
      keyword_chosen: "",
      datas: [],
      year_datas: [],
      month_datas: [],
      keyword_datas: [],
      word_cloud_url: "",
      pageOfItems: [],
      customLabels: {
        first: "Pertama",
        last: "Terakhir",
        previous: "Sebelumnya",
        next: "Seterusnya",
      },
    };
  },
  computed: {
    HostUrl() {
      return this.$store.state.host_url;
    },
    filteredMonthDatas() {
      if (this.year_chosen === "2023") {
        return this.month_datas.filter((month) => month.Month <= 7);
      }
      return this.month_datas;
    },
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    getYear() {
      axios.post(this.HostUrl + "dashboard.php", { request: "year" }).then((response) => {
        this.year_datas = response.data;
      });
    },
    getMonth() {
      axios.post(this.HostUrl + "dashboard.php", { request: "month" }).then((response) => {
        this.month_datas = response.data.sort((a, b) => a.Month - b.Month);
      });
    },
    getKeyword() {
      axios.post(this.HostUrl + "dashboard.php", { request: "keyword" }).then((response) => {
        this.keyword_datas = response.data;
      });
    },
    getTweet() {
      axios
        .post(this.HostUrl + "dashboard.php", {
          request: "dashboard",
          year: this.year_chosen,
          month: this.month_chosen,
          keyword: this.keyword_chosen,
        })
        .then((response) => {
          this.datas = response.data;
        });

      axios
        .post(this.HostUrl + "dashboard.php", {
          request: "word_cloud",
          year: this.year_chosen,
          month: this.month_chosen,
          keyword: this.keyword_chosen,
        })
        .then((response) => {
          this.word_cloud_url = response.data;
        });
    },
    onChangeResult() {
      if (this.year_chosen && this.month_chosen && this.keyword_chosen) {
        this.getTweet();
      }
    },
    onChangePage(datas) {
      this.pageOfItems = datas;
    },
  },
  created() {
    store.commit("HostUrl",  `${window.location.origin}/php_script/`);
    this.getYear();
    this.getMonth();
    this.getKeyword();
  },
};
</script>

<style scoped>
.page-button-container {
  display: flex;
  width: 100%;
}
.page-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 10%;
  padding: 10px 20px;
  min-height: 50px;
  background-color: var(--main-grey-1);
  width: fit-content;
  border-radius: 6px 6px 0 0;
  font-weight: 600;
  color: #fff;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.page-button.active {
  background-color: var(--main-green);
}
.page-button-content {
  margin: 0 auto;
  padding: 3rem 1.5rem;
  border: 1px solid rgb(0 0 0 / 0.1);
  border-top: 3px solid var(--main-green);
  border-radius: 0 0 6px 6px;
}
</style>
