<template>
  <div class="main-nav shadow">

    <!-- Side Nav Button -->
    <div class="container side-nav-btn">
      <div><i class="bi bi-list"></i></div>
    </div>

    <!-- Menus -->
    <div class="overlay"><i class="bi bi-x"></i></div>
    <div class="container side-nav">

      <div class="side-nav-login" v-if="!IsLogin">
        <a href="/login">
          Log Masuk <i class="bi bi-box-arrow-in-right"></i>
        </a>
        <a v-if="IsLogin" @click="logout">
          Log Keluar <i class="bi bi-box-arrow-in-right"></i>
        </a>
      </div>

      <div class="nav-options" v-if="IsLogin">        

        <a href="/" class="option">
          Home
        </a>       
        <a href="/user-list" class="option" v-if="Username=='angjinsheng@gmail.com'">
          User List
        </a>        

        <a href="/keywords" class="option">
          Keywords
        </a>     
      </div>   

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import store from "../store";
export default {
  data(){
    return{}
  },
  methods: {
    logout(){
        store.commit("IsLogin", false);
        store.commit("Username", "");
        this.$router.push("/login");
    },
  },
  created(){
    
  },
  mounted(){

    // Run function when screen resize
    window.addEventListener('resize',()=>{
      checkScreenSize();
      removeActive(mainNav);
      removeFixedBody();
    });

    const mainNav = document.querySelector('.main-nav');    
    const navBtn = document.querySelector('.side-nav-btn');
    const closeArea = mainNav.querySelector('.overlay');
    const links = mainNav.querySelectorAll('a');
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    const navTop = mainNav.offsetTop;    
    let pageTop = window.pageYOffset;
    let currentY;

    // initiate function
    function initiate () {
      checkScreenSize();
      stickyMenu();
      sideNavButton();
      sideNavCloseArea();
      clickLinks();
    }

    // check and add class to target
    function addClassTo ({target, className}) {
      if (!target.classList.contains(className)) {
          return target.classList.add(className);
        }  
    }

    // check screen size and decide which mode to go
    function checkScreenSize () {      
      const screenWidth = window.innerWidth;
      // console.log(screenWidth);
      if (screenWidth<960){
        addClassTo({target: body, className: 'mobile'})    
      } else {
          body.classList.remove('mobile');
        }
    }

    // stick main menu to the top whn scroll down 
    function stickyMenu() {
      document.addEventListener('scroll',()=>{
        pageTop = window.pageYOffset;
        if ( pageTop > navTop) {
          addClassTo({target: mainNav, className:'fixed'});
        } else {
          mainNav.classList.remove('fixed');
        }
      })
    }

    // reset the active
    function removeActive (target) {
      target.classList.remove('active');
    }

    // setup button to show side nav
    function sideNavButton () {
      navBtn.addEventListener('click',()=>{
        addClassTo({target:mainNav, className: 'active'});
        currentY = window.pageYOffset;
        console.log(window.pageYOffset);
        fixedBody();
      })
    }

    // Fix the background when sidenav is active
    function fixedBody () {      
      addClassTo({target: body, className: 'fixed'});       
      html.style.scrollBehavior = "auto";
      body.style.top = `-${currentY}px`
    }

    // Remvo fixed body 
    function removeFixedBody () {
      body.classList.remove('fixed');      
      body.removeAttribute('style');      
      window.scrollTo(0, currentY);
      html.removeAttribute('style');      
    }

    // setup area that close sideNav
    function sideNavCloseArea () {
      closeArea.addEventListener('click', (e)=> {
        if (closeArea.contains(e.target)) {
          removeActive(mainNav);
          removeFixedBody();
        }
      })
    }

    // close sideNav after any links is clicked
    function clickLinks () {
      for (const link of links) {
        link.addEventListener('click', ()=>{
          removeActive(mainNav);
        });
      }
    }
    
    initiate();
  },
  updated(){
    
  },
  computed: {
    
    HostUrl() {
        return this.$store.state.host_url;
    },
    IsLogin() {
        return this.$store.state.is_login;
    },
    Username() {
      return this.$store.state.username;
    },

  }

}

</script>
<style>
    body.fixed {
      position: fixed;
      left: 0;
      width: 100%;      
    }
    .main-nav{
        background-color: var(--main-purple);
        min-height: var(--nav-height);        
        color: #fff;
    }
    .main-nav.fixed{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
    }
    .main-nav .overlay {
      opacity: 0;
      pointer-events: none;
      display: none;
    }
    body.mobile .main-nav.active .overlay {
      display: block;
      background-color: rgb(0 0 0/ .85);      
      backdrop-filter: blur(4px);
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
      opacity: 1;
      pointer-events: initial;
    }
    body.mobile .main-nav.active .overlay i{      
      position: absolute;
      top: 0;
      left: .25rem;
      font-size: 50px;
      opacity: .7;
      cursor: pointer;
    }
    .main-nav .container{
      padding: 5px 0;
      min-height: var(--nav-height);
    }
    body.mobile .main-nav .container.side-nav{
      position: fixed;
      right: 0;
      top: 0;
      width: 60%;
      height: 100vh;
      background-color: var(--main-purple);
      z-index: 1000;
      transition: transform .35s ease;
      transform: translateX(100%);
      pointer-events: none;
      opacity: 0;
    }
    body.mobile .main-nav.active .container.side-nav{
      transform: translateX(0);
      pointer-events: initial;
      opacity: 1;
    }
    .nav-options{
      display: flex;
      flex: 1;      
      position: relative;
    }
    body.mobile .nav-options {
      display: block;
      width: 100%;
    }
    .main-nav .option{
      color: #fff;
      text-decoration: none;
      padding: 0 35px;
      min-height: var(--nav-height);
      display: flex;
      align-items: center;
      width: fit-content;
      cursor: pointer;
      transition: background-color .35s ease;
      border-radius: 6px;
    }
    body.mobile .main-nav .option{
      width: 94%;
      margin: 1rem auto;
    }
    .side-nav-login{
      display: none;
    }
    body.mobile .side-nav-login{
      margin: 1rem auto;
      width: 100%;
      display: flex;
    }
    body.mobile .side-nav-login a{
      text-decoration: none;
      color: #fff !important;
      min-width: 40%;
      margin: 0 auto;
      border: solid 1px #fff;
      border-radius: 2rem;
      padding: 1rem;
      text-align: center;
      transition: background-color .35s ease;                  
    }    
    .side-nav-login a:hover{
      background-color: rgb(255 255 255 / .2);
    }
    .main-nav .option:hover{
      background-color: rgb(255 255 255/.25);
    }
    .side-nav-btn{
      display: none;
    }
    body.mobile .side-nav-btn{
      display: flex;
      width: 100%;
      /* justify-content: end; */
      font-size: 1.6rem;    
      cursor: pointer;;
    }
    .side-nav-btn div{
      border: 1px solid #fff;
      width: fit-content;
      padding: 0 10px;
      margin: 5px 1rem 5px auto;
      border-radius: 4px;
    }
</style>
