<template>
  <v-app>
    <div id="app" style="height: 100%;">
        <router-view />
    </div>

  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {
  
  },
  computed: {
    

  }
}
</script>

<style>
  #app{
    background: #f2f2f2;
  }
  
  
  .register-section{
    margin-top: 40px;
    padding: 25px 25px 0 25px;
    border-top: 1px solid rgb(0 0 0 / 10%);
    text-align: left;
    display: flex;
  }
  .register-section a{
    text-decoration: none;
  }
</style>

