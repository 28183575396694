<template>
    <Bar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="200"
    />
</template>
  
<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import axios from 'axios';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'BarChart',
    components: { Bar },
    props: {
        chartId: {
        type: String,
        default: 'bar-chart'
        },
        datasetIdKey: {
        type: String,
        default: 'label'
        },
        width: {
        type: Number,
        default: 400
        },
        height: {
        type: Number,
        default: 400
        },
        cssClasses: {
        default: '',
        type: String
        },
        styles: {
        type: Object,
        default: () => {}
        },
        plugins: {
        type: Array,
        default: () => [
            
        ]
        }
    },
    data() {
        return {
            chartData: {
                labels: [],
                datasets: [],
            },
            chartOptions: {
                responsive: true,
                scales: {
                    x: {
                        stacked: true,
                        ticks: {
                            font: {
                                size: 20,
                            }
                        }
                    },
                    y: {
                        stacked: true,
                        ticks: {
                            font: {
                                size: 20,
                            }
                        }
                    },
                   

                },
                plugins: {
                    legend: {
                        display: true,
                        labels: {
                   
                            font: {
                                size: 20
                            }
                        }
                    
                    },
                }
            },
            date_array:[],
            perilaku_array:[],
            pengetahuan_array:[],
            persepsi_array:[],
            datas:[],
            url: ""
        }
    },
    methods: {
        getData() {
            console.log(this.url)
            axios.post(this.url,{
                request:'stacked_graph'
            })
            .then(response => {
                
                this.datas = response.data;
                
                var i = 0;
                for(i= 0; i< this.datas.length; i++){
                    this.date_array.push(this.datas[i]['date'])
                    this.perilaku_array.push(this.datas[i]['total_perilaku'])
                    this.pengetahuan_array.push(this.datas[i]['total_pengetahuan'])
                    this.persepsi_array.push(this.datas[i]['total_persepsi'])
                }
               
                
            })
            .catch(function (error) {
                console.log(JSON.stringify(error));
                console.log("error response :" + error.response)
                console.log("error status :" + error.status)
            });
           
            this.chartData = {
                labels: this.date_array,

                datasets: [
                    {
                        label: 'Perilaku',
                        data: this.perilaku_array,
                        backgroundColor: "red",
                    },
                    {
                        label: 'Pengetahuan',
                        data: this.pengetahuan_array,
                        backgroundColor: "blue",
                    },
                    {
                        label: 'Persepsi',
                        data: this.persepsi_array,
                        backgroundColor: "green",
                    },
                    ],
               

                };
        }
    },
    created() {

        this.url = this.HostUrl + "dashboard.php"

                                    
        this.getData()

    },
    computed: {
        HostUrl() {
            return this.$store.state.host_url;
        },


    }

}
</script>