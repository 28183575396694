<template>
    <div>
        <HeaderNav/>
        <MainNav/>

        <div class="container">
            <!-- ===== Content ===== -->
            <div class="body-container table-box">
                <div class="row mx-auto">
                    <div class="col-12">
                        <div class="dashboard-content-card text-center">
                            <h3><b>User List for Admin in LPPKN Analytics</b></h3>
                            <hr>
                            <div class="dashboard-table">
                                <div class="table-responsive" v-if="datas != null">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th class="text-left">ID</th>
                                                <th class="text-left">Email</th>
                                                <th class="text-left">Password</th>
                                                <th>Action </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td><input type='email' v-model='email'></td>

                                                <td><input type='text' v-model='password'></td>
                                                <td>
                                                    <v-btn depressed color="green" dark @click="addUser">Add User</v-btn>
                                                </td>

                                            </tr>
                                            <tr v-for="(data, index) in datas" :key="index">
                                                <td>{{ data.ID }}</td>
                                                <td><input type='email' v-model='data.email'></td>
                                                <td><input type='text' v-model='data.password'></td>
                                    
                                                <td>
                                                    <v-btn depressed color="blue" dark @click="editUser(index,data.ID)">Update </v-btn>
                                                    <v-btn depressed color="red" dark @click="deleteUser(data.ID)">Delete </v-btn>
                                                </td>
                                            </tr>

                                        </tbody>

                                    </table>
                                </div>
                            </div>
                            
                        </div>  <!-- <=== END dashboard-content-card -->
                    </div>
                </div>
            </div>  <!-- <=== End Content  -->
        </div>

        <FooterBar/>
    </div>
</template>

<script>
import axios from 'axios';
import HeaderNav from '@/components/HeaderNav.vue';
import MainNav from '@/components/MainNav.vue';
import FooterBar from '@/components/FooterBar.vue';
//import store from '../store'
export default {
    name: "AdminUserlist",
    components: {
    HeaderNav,
    MainNav,
    FooterBar
},
    data() {

        return {
            datas: [],
            show: false,
            email: "",
            password: "",
            url:""
        };
    },
    methods: {
        addUser() {

            if (this.email != '' && this.password != '') {
                axios.post(this.url, {
                        request: 2,
                        email: this.email,
                        password: this.password,
                        created_by: this.Username

                    })
                    .then(response => {
                        this.name = '';
                        this.email = '';
                        this.password = '';

                        this.show_user();
                        console.log(response.data);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                alert('Fill all fields.');
            }

        },
        editUser(index, id) {
            // update meetings in firestores
            var email = this.datas[index].email;
            var password = this.datas[index].password;
    

            axios.post(this.url, {
                    request: 3,
                    id: id,
                    email: email,
                    password: password,
                    updated_by: this.Username
                })
                .then(response => {
                    alert(response.data);
                    this.show_user();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        show_user() {
            axios.post(this.url, {
                    request: 1,
                })
                .then(response => {

                    this.datas = response.data;

                })
                .catch(function (error) {
                    console.log(JSON.stringify(error));
                    console.log("error response :" + error.response)
                    console.log("error status :" + error.status)
                });
        },
        deleteUser(id) {

            axios.post(this.url, {
                    request: 4,
                    id: id

                })
                .then(response => {
                    alert(response.data);
                    this.show_user();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
    created() {

        this.url = this.HostUrl +  "user_list.php"
        console.log(this.url)
        this.show_user()

    },

    computed: {
        HostUrl() {
            return this.$store.state.host_url;
        },
        ScriptUrl() {
            return this.$store.state.script_url;
        },
        Username() {
            return this.$store.state.username;
        }
    }

};
</script>

<style>
.v-select {
    display: grid;
}
td button{
    margin: 2px 1px;
}
</style>
